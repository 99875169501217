<template>
    <div :id="uppyId" class="drag-drop-file" />
</template>

<script>
import uppy from "@uppy/core";
import DragDrop from "@uppy/drag-drop";
import uuidv4 from "uuid/v4";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

export default {
    name: "DragDropFile",
    data() {
        return {
            uppyId: `uppy-${uuidv4()}`
        }
    },
    mounted() {
        const restrictions = {
            maxNumberOfFiles: 1,
            minNumberOfFiles: 1,
            maxFileSize: 5242880, /* 5mb */
            allowedFileTypes: [".png", ".jpg", ".jpeg", ".png", ".webp", ".heif", ".svg"]
        };

        const defaultUppyConfig = {
            autoProceed: true,
            debug: false,
            restrictions
        };

        const uppyInstance = uppy({
            id: this.uppyId,
            ...defaultUppyConfig
        });

        uppyInstance.use(DragDrop, {
            target: `#${this.uppyId}`,
            width: "100%",
            height: "500px"
        });

        uppyInstance.on("info-visible", () => {
            const info = uppyInstance.getState().info

            this.$notify({
                group: "app-notifications",
                type: "error",
                text: `${info.message} ${info.details}`,
                duration: 3000
            });
        })

        uppyInstance.on("file-added", (file) => {
            this.$emit("file-added", file);
        });

        this.uppyInstance = uppyInstance;
    }
}
</script>

<style lang="scss" scoped>
.drag-drop-file {
    /deep/ .uppy-DragDrop-container {
        border: 0;
        background-color: transparent;

        .uppy-c-icon {
            display: none;
        }

        .uppy-DragDrop-label {
            font-size: 25px;
            font-weight: 400;

            span {
                color: $primary-color;
            }
        }

        &:focus {
            box-shadow: none;
        }
    }
}
</style>
